import React from "react"
import loadable from "@loadable/component"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import BlockContent from "@sanity/block-content-to-react"
import Iframe from "react-iframe"
import styled from "styled-components"
import Slide from "../components/slideIntro"
// import ReactPannellum, { getConfig } from "react-pannellum"
// import { Pannellum, PannellumVideo } from "pannellum-react"
// const ReactIntense from "react-intense"
const ReactIntense = loadable(() => import("../../node_modules/react-intense"))

const ImageWrap = styled.div`
  .ri-trigger {
    width: 925px;
    height: 600px;
    background-size: cover;
    background-position: 50% 50%;
    margin: 0 auto;
    display: block;
  }
`

const Header = styled.h1`
  font-size: 32px;
  text-align: center;
  i {
    color: pink;
  }
`

const Block = styled.div``

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 1280px) {
    grid-template-columns: 1fr;
    justify-items: center;
  }
`

const PanPage = ({ data }) => {
  return (
    <Layout>
      <Grid>
        <Block>
          <Header>
            <i>Click Image</i> to view entire classroom
          </Header>
          <ImageWrap>
            <ReactIntense src={data?.fileName?.publicURL} />
          </ImageWrap>
        </Block>
        <Block>
          <Header>
            <i>Click Image</i> to view entire classroom
          </Header>
          <ImageWrap>
            <ReactIntense src={data?.pano2?.publicURL} />
          </ImageWrap>
        </Block>
      </Grid>
    </Layout>
  )
}

export default PanPage

export const query = graphql`
  query {
    fileName: file(relativePath: { eq: "pano.jpeg" }) {
      publicURL
    }
    pano2: file(relativePath: { eq: "pano-2.jpeg" }) {
      publicURL
    }
  }
`
